// extracted by mini-css-extract-plugin
export var designDevelopmentExpertsSection = "q_bY";
export var designDevelopmentServices = "q_bW";
export var designDifferenceBetweenSection = "q_bX";
export var designFeaturedCaseSection = "q_b5";
export var designFullVideoSection = "q_b0";
export var designInsightsSection = "q_b6";
export var designPhaseSection = "q_b2";
export var designPrimeSection = "q_bV";
export var designProcessSection = "q_b1";
export var designProjLogoSection = "q_b3";
export var designTestimonialPosSection = "q_bZ";
export var domainExpertiseDesign = "q_b4";